<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>內容管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>活動管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 font-weight-bold">活動管理</h4>
        <div
          class="
            col-12 col-xl-6
            mb-2
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.EVENT_MODIFY])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'EventCreate' }"
            ><i class="fa fa-plus"></i>新增活動</b-button
          >
          <b-input-group>
            <b-form-input
              v-model="query.keyword"
              placeholder="搜尋"
              v-on:keyup.enter="handlePageReset"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <span>活動區間：</span>
          <datepicker
            placeholder="Select Date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :value="query.start_at"
            @selected="handleStartAtSelected"
          ></datepicker>
          <span class="mx-2">~</span>
          <datepicker
            placeholder="Select Date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :value="query.end_at"
            @selected="handleEndAtSelected"
          ></datepicker>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="events"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.EVENT_CUSTOMER_LIST_VIEW])"
                    variant="inverse-success"
                    :to="{
                      name: 'EventCustomerList',
                      params: { event_id: data.item.id },
                    }"
                  >
                    活動會員
                  </b-button>
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'EventView',
                      params: { event_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.EVENT_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'EventForm',
                      params: { event_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.EVENT_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteEvent(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import eventApi from "@/apis/event";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import Datepicker from "vuejs-datepicker";
import { format, addMonths, subMonths, endOfDay, startOfDay } from "date-fns";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  mixins: [paginationMixin],
  components: { Datepicker, CustomPagination },
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      events: [],
      initialized: false,
      fields: [
        {
          key: "name",
          label: "活動名稱",
        },
        {
          key: "code",
          label: "活動代碼",
        },
        {
          key: "start_at",
          label: "開始時間",
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          key: "end_at",
          label: "截止時間",
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
      query: {
        page: 1,
        per_page: 10,
        keyword: null,
        start_at: this.formatDate(subMonths(new Date(), 1)),
        end_at: this.formatDate(addMonths(new Date(), 1)),
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.getEvents();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getEvents();
      this.initialized = true;
    },
    async getEvents() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
        };

        const { data } = await eventApi.getEvents(this.organization, params);
        this.events = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得活動資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteEvent(event) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>活動名稱：${event.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await eventApi.removeEvent(this.organization, event);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + event.name,
              });
              this.getEvents();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    handleStartAtSelected(startAt) {
      this.query.start_at = this.formatDate(startOfDay(startAt));
      this.handlePageReset();
    },
    handleEndAtSelected(endAt) {
      this.query.end_at = this.formatDate(endOfDay(endAt));
      this.handlePageReset();
    },
    formatDate(date) {
      if (!date) return null;
      if (typeof date === "string") return date;
      return format(date, "yyyy-MM-dd HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
